import * as React from "react";

interface Props {
  className?: string;
}

const WindmillWordmark = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141 40" className={className}>
    <path
      fill="#4084FB"
      fillRule="evenodd"
      d="M17.023 12.372a8.14 8.14 0 0 1 2.85-.56 20.003 20.003 0 0 0-.232-1.061c-1.15.044-2.247.3-3.252.727.216.287.428.585.634.894Zm-6.213 8.491a9.366 9.366 0 0 1 .28-3.31c.36.024.72.06 1.08.105a8.167 8.167 0 0 0-.315 2.88c-.357.1-.706.21-1.045.325Zm2.28 5.24a9.233 9.233 0 0 1-1.715-2.827c.305-.19.618-.372.936-.547.329.92.819 1.765 1.435 2.499-.23.29-.45.582-.655.874Zm4.9 2.89a9.182 9.182 0 0 1-3.032-1.278c.136-.333.283-.664.438-.992.784.537 1.665.94 2.613 1.178-.017.37-.023.735-.02 1.093Zm5.647-.521a9.185 9.185 0 0 1-3.197.731c-.085-.35-.16-.703-.227-1.06a8.14 8.14 0 0 0 2.797-.568c.204.31.414.609.627.896Zm4.274-3.72a9.288 9.288 0 0 1-2.166 2.46 20.463 20.463 0 0 1-.806-.725 8.21 8.21 0 0 0 1.937-2.094c.348.132.693.251 1.035.358Zm1.297-5.529a9.239 9.239 0 0 1-.316 3.272c-.36-.027-.72-.065-1.08-.113a8.164 8.164 0 0 0 .347-2.838c.36-.1.709-.206 1.049-.32Zm-2.198-5.26a9.224 9.224 0 0 1 1.671 2.849c-.307.19-.621.371-.94.545a8.153 8.153 0 0 0-1.39-2.517c.232-.292.452-.585.659-.877Zm-4.895-2.976a9.187 9.187 0 0 1 3.044 1.329c-.136.334-.283.666-.438.995a8.13 8.13 0 0 0-2.62-1.228c.015-.372.02-.738.014-1.097Zm-10.054 4.277a9.282 9.282 0 0 1 2.191-2.511c.276.231.547.473.813.72a8.211 8.211 0 0 0-1.966 2.141 19.358 19.358 0 0 0-1.038-.35Z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fill="#4084FB"
      fillRule="evenodd"
      d="M12.861 8.008a13.842 13.842 0 0 1 4.711-1.755 3.794 3.794 0 0 0-1.064-.87c-1.68.4-3.249 1.084-4.656 1.996.335.185.673.395 1.01.629ZM5.516 23.893a15.019 15.019 0 0 1-.476-5.029 3.8 3.8 0 0 1 1.148-.734 14.065 14.065 0 0 0 .239 5.002c-.327.246-.63.5-.91.761Zm5.059 7.752a15.078 15.078 0 0 1-3.34-3.79c.076-.376.226-.788.508-1.25a13.994 13.994 0 0 0 3.126 3.894c-.12.39-.219.772-.294 1.146Zm8.618 3.309a14.921 14.921 0 0 1-4.936-1.118 3.922 3.922 0 0 1-.302-1.304 13.842 13.842 0 0 0 4.807 1.321c.13.386.274.753.431 1.1Zm8.916-2.358a14.92 14.92 0 0 1-4.673 1.984 3.98 3.98 0 0 1-.995-.886 13.846 13.846 0 0 0 4.673-1.737c.331.236.664.45.995.64Zm5.856-7.136a14.992 14.992 0 0 1-2.643 4.354A4.007 4.007 0 0 1 30 29.676a13.956 13.956 0 0 0 2.781-4.148c.409-.003.804-.026 1.184-.068Zm.561-9.238a15.03 15.03 0 0 1 .416 5.082c-.289.248-.656.48-1.153.676a14.057 14.057 0 0 0-.18-5.003c.328-.244.634-.496.917-.755Zm-4.992-7.827a15.073 15.073 0 0 1 3.327 3.857 3.91 3.91 0 0 1-.524 1.243 13.984 13.984 0 0 0-3.097-3.947c.121-.392.22-.777.294-1.153ZM20.865 5c1.747.099 3.414.497 4.95 1.144.163.353.287.778.331 1.324a13.842 13.842 0 0 0-4.836-1.365A11.67 11.67 0 0 0 20.865 5Zm-14.88 9.615c.38-.047.777-.076 1.186-.084a13.949 13.949 0 0 1 2.766-4.192 3.78 3.78 0 0 0-1.372-.073 14.992 14.992 0 0 0-2.58 4.349Z"
      clipRule="evenodd"
    />
    <path
      fill="#4084FB"
      fillRule="evenodd"
      d="M14.545 9.392a11.825 11.825 0 0 1 4.193-1.255 10.272 10.272 0 0 0-.535-1.01c-1.617.224-3.14.746-4.51 1.51.287.234.571.486.852.755ZM7.282 22.539a13.033 13.033 0 0 1-.072-4.737c.33-.079.702-.145 1.122-.196a11.96 11.96 0 0 0-.07 4.36c-.342.183-.67.374-.98.573Zm3.933 6.98a13.015 13.015 0 0 1-2.835-3.773c.223-.256.485-.527.794-.814a11.933 11.933 0 0 0 2.5 3.55c-.17.349-.323.695-.459 1.037Zm7.256 3.338a12.883 12.883 0 0 1-4.496-1.392c.031-.338.086-.711.17-1.123a11.824 11.824 0 0 0 4.09 1.407 15 15 0 0 0 .236 1.108Zm7.822-1.537a12.884 12.884 0 0 1-4.456 1.498 10.833 10.833 0 0 1-.52-1.011 11.825 11.825 0 0 0 4.135-1.247c.278.27.559.524.841.76Zm5.448-5.824a13.005 13.005 0 0 1-2.744 3.824 10.924 10.924 0 0 1-1.014-.515 11.947 11.947 0 0 0 2.628-3.43c.385.056.762.096 1.13.121Zm1.007-7.93a13.038 13.038 0 0 1 .019 4.719c-.333.073-.706.134-1.124.181a11.957 11.957 0 0 0 .12-4.331c.344-.182.672-.372.985-.569Zm-3.854-7.034a13.011 13.011 0 0 1 2.798 3.819c-.225.256-.49.526-.8.811a11.926 11.926 0 0 0-2.459-3.589c.17-.35.324-.697.46-1.04Zm-7.282-3.43c1.62.201 3.147.7 4.524 1.442-.03.34-.083.715-.165 1.13a11.827 11.827 0 0 0-4.116-1.46c-.069-.383-.15-.754-.244-1.111ZM8.213 14.552c.368.02.746.057 1.131.11a11.947 11.947 0 0 1 2.64-3.487c-.37-.206-.711-.37-1.025-.501a13.002 13.002 0 0 0-2.746 3.878Z"
      clipRule="evenodd"
      opacity={0.8}
    />
    <path
      fill="#4084FB"
      fillRule="evenodd"
      d="M15.909 10.867a9.928 9.928 0 0 1 3.53-.859 14.461 14.461 0 0 0-.352-1.046 10.982 10.982 0 0 0-3.91 1.068c.247.265.492.544.732.837ZM9.063 21.565a11.142 11.142 0 0 1 .159-4.037c.362-.018.73-.022 1.1-.013a10.004 10.004 0 0 0-.238 3.614c-.352.137-.693.283-1.02.436Zm3.025 6.124c-.936-.96-1.7-2.09-2.238-3.34.282-.227.578-.445.883-.655a9.984 9.984 0 0 0 1.926 3.046c-.204.317-.394.634-.57.95Zm6.02 3.174a10.98 10.98 0 0 1-3.757-1.39c.096-.348.209-.698.334-1.047a9.923 9.923 0 0 0 3.33 1.334c.02.376.05.744.092 1.103Zm6.72-.946c-1.18.574-2.48.945-3.85 1.065-.127-.34-.24-.689-.344-1.045a9.927 9.927 0 0 0 3.471-.86c.237.293.479.573.723.84Zm4.9-4.696a11.098 11.098 0 0 1-2.504 3.115 14.808 14.808 0 0 1-.891-.645 10.03 10.03 0 0 0 2.313-2.725c.365.098.726.183 1.081.255Zm1.228-6.69a11.137 11.137 0 0 1-.204 4.003c-.363.015-.73.015-1.102.003a9.995 9.995 0 0 0 .281-3.574c.354-.136.695-.28 1.025-.431Zm-2.94-6.161a11.06 11.06 0 0 1 2.195 3.374c-.284.226-.582.444-.89.653a9.983 9.983 0 0 0-1.879-3.074c.206-.318.397-.636.574-.953Zm-6.028-3.267a10.98 10.98 0 0 1 3.777 1.443c-.096.35-.207.702-.332 1.053a9.927 9.927 0 0 0-3.346-1.388 17.257 17.257 0 0 0-.099-1.108Zm-11.757 5.703a11.094 11.094 0 0 1 2.52-3.172c.305.198.605.411.9.637a10.029 10.029 0 0 0-2.337 2.78 17.362 17.362 0 0 0-1.083-.245Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <path
      fill="#202631"
      d="m61.577 28.5-3.314-13.137L54.95 28.5h-3.197l-4.806-16.8h3.01l3.5 13.16L56.77 11.7h3.01l3.313 13.183L66.57 11.7h3.033l-4.806 16.8h-3.22Zm9.176 0V16.367h2.683V28.5h-2.683Zm0-14.163V11.7h2.683v2.637h-2.683Zm12.921 6.743c0-1.517-.723-2.637-2.45-2.637-1.003 0-2.963.607-2.963 3.36V28.5h-2.683V16.367h2.59v1.703c.84-1.143 2.1-1.983 3.85-1.983 2.87 0 4.316 2.006 4.316 4.69V28.5h-2.66v-7.42Zm16.32 7.42h-2.59v-1.703c-.84 1.236-2.124 1.983-3.897 1.983-3.57 0-5.507-2.73-5.507-6.347 0-3.616 1.936-6.346 5.507-6.346 1.703 0 2.963.7 3.803 1.843V11.7h2.683v16.8Zm-9.24-6.067c0 2.404 1.05 4.06 3.313 4.06 2.24 0 3.29-1.656 3.29-4.06 0-2.403-1.05-4.06-3.29-4.06-2.264 0-3.314 1.657-3.314 4.06Zm26.615-1.353c0-1.517-.723-2.637-2.193-2.637-1.004 0-2.707.607-2.707 3.36V28.5h-2.707v-7.42c0-1.517-.7-2.637-2.17-2.637-1.003 0-2.73.607-2.73 3.36V28.5h-2.683V16.367h2.59v1.726c.723-1.143 1.867-2.006 3.617-2.006s2.986.886 3.616 2.286c.794-1.283 2.1-2.286 3.967-2.286 2.613 0 4.083 2.006 4.083 4.69V28.5h-2.683v-7.42Zm4.855 7.42V16.367h2.683V28.5h-2.683Zm0-14.163V11.7h2.683v2.637h-2.683Zm4.825-2.637h2.683v13.487c0 .816.327 1.19 1.19 1.19h1.61V28.5h-2.24c-2.076 0-3.243-.723-3.243-3.01V11.7Zm6.989 0h2.683v13.487c0 .816.327 1.19 1.19 1.19h1.61V28.5h-2.24c-2.077 0-3.243-.723-3.243-3.01V11.7Z"
    />
  </svg>
);
export default WindmillWordmark;
